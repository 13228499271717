<template>
  <div>
    <!-- ----------------------------------------------- -->
    <!-- top cards -->
    <!-- ----------------------------------------------- -->
    <!-- <b-row>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">+70%</h4>
          <b-card-text class="mb-2">Total Sales</b-card-text>
          <b-progress value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">-10%</h4>
          <b-card-text class="mb-2">Monthly Sales</b-card-text>
          <b-progress variant="danger" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">50%</h4>
          <b-card-text class="mb-2">Yearly Sales</b-card-text>
          <b-progress variant="success" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">30%</h4>
          <b-card-text class="mb-2">Company Growth</b-card-text>
          <b-progress variant="warning" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
    </b-row> -->
    <!-- ----------------------------------------------- -->
    <!-- end top cards -->
    <!-- ----------------------------------------------- -->
    <b-row>
      <b-col cols="12" lg="12">
        <b-card class="mb-4">
          <h4 class="card-title"></h4>
          <!-- <SalesSummary /> -->
          <div class="map-frame">
            <div id="catasto"></div>
          </div>
        </b-card>
      </b-col>
      <!-- <b-col cols="12" lg="4">
        <b-card class="mb-4">
          <h4 class="card-title">Indici di rischio</h4>
          <br><br>
          <b-row>
            <b-col>
              <PercentageCircle :percent="firstRisk" :active-color="getColor(firstRisk)" size="big" :animate="true" complete-color="green" class="percentage" />
            </b-col>
            <b-col>
              <PercentageCircle :percent="secondRisk" :active-color="getColor(secondRisk)" size="big" :animate="true" complete-color="green" class="percentage" />
            </b-col>
          </b-row>
          <br><br>
          <b-row>
            <b-col>
              <PercentageCircle :percent="thirdRisk" :active-color="getColor(thirdRisk)" size="big" :animate="true" complete-color="green" class="percentage" />
            </b-col>
          </b-row>
        </b-card>
      </b-col> -->
    </b-row>
    <!-- ----------------------------------------------- -->
    <!-- top selling products -->
    <!-- ----------------------------------------------- -->
    <!-- <b-card class="mb-4" no-body>
      <b-card-body>
        <h4 class="card-title">Top Selling Products</h4>
        <h6 class="card-subtitle font-weight-normal text-muted">
          Overview of Latest Month
        </h6>
      </b-card-body>
      <SellingProduct />
    </b-card> -->
    <!-- ----------------------------------------------- -->
    <!-- end top selling products -->
    <!-- ----------------------------------------------- -->
    <!-- ----------------------------------------------- -->
    <!-- cards row -->
    <!-- ----------------------------------------------- -->
    <!-- <TopCards /> -->
    <!-- ----------------------------------------------- -->
    <!-- end cards row -->
    <!-- ----------------------------------------------- -->
    <!-- <b-row>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">+70%</h4>
          <b-card-text class="mb-2">Total Sales</b-card-text>
          <b-progress value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">-10%</h4>
          <b-card-text class="mb-2">Monthly Sales</b-card-text>
          <b-progress variant="danger" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">50%</h4>
          <b-card-text class="mb-2">Yearly Sales</b-card-text>
          <b-progress variant="success" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card class="mb-4">
          <h4 class="card-title mb-1">30%</h4>
          <b-card-text class="mb-2">Company Growth</b-card-text>
          <b-progress variant="warning" value="100" max="100"></b-progress>
        </b-card>
      </b-col>
    </b-row> -->
    
  </div>
</template>

<script>
import axios from 'axios';
import "leaflet/dist/leaflet.css"
export default {
  name: "Starter",
  data: () => ({
    firstRisk: 50,
    secondRisk: 30,
    thirdRisk: 90,
    geojson: undefined,
    geojsonOptions: null,
    mapIsReady: false,
    jsonIsReady: false,
    zoom: 8,
    geoStyler: (feature) => ({
        opacity: feature.properties.code / 100000,
      }),
  }),
  mounted(){
    
    // var osm = window.L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    //     maxZoom: 19,
    //     attribution: '© OpenStreetMap contributors, Tiles style by Humanitarian OpenStreetMap Team hosted by OpenStreetMap France'});
    var map = window.L.map('catasto', {
        center: [45.490682, 9.124533],
        zoom: 15,
    });
    
    window.L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    }).addTo(map);

    var style1 = { // Define your style object
        "color": "#880808"
    };

    var style2 = { // Define your style object
        "color": "#008000"
    };

    axios.get('/catasto3.geojson').then(res => {
      console.log(res);
      this.geojson=res.data;
      this.jsonIsReady=true;
      // window.L.geoJSON(this.geojson).addTo(map);

      window.L.geoJson(this.geojson, {
        style: style1,
        filter: function(feature) {
          return (feature.properties.TIPOLOGIA === null);
        }
      }).addTo(map);

      window.L.geoJson(this.geojson, {
        style: style2,
        filter: function(feature) {
          return (feature.properties.TIPOLOGIA !== null);
        }
      }).addTo(map);
      
    });
    
    
    // var baseMaps = {
    // "Fire Risk": osm,
    
    // };


    // window.L.control.layers(baseMaps).addTo(map);

    // var baseMaps = {
    //   "OpenStreetMap": osm,
    //   "<span style='color: red'>OpenStreetMap.HOT</span>": osmHOT
    // };

    // console.log(layerControl, baseMaps)

  
  },
  async beforeMount() {
    // HERE is where to load Leaflet components!
    const { circleMarker } = await import("leaflet/dist/leaflet-src.esm");

    // And now the Leaflet circleMarker function can be used by the options:
    this.geojson.pointToLayer = (feature, latLng) =>{
      if(feature.TIPOLOGIA == null){
        circleMarker(latLng, { radius: 8 });
      }
    }
    this.mapIsReady = true;
  },
  methods: {
    getColor(risk){
        if(risk <= 40){
          return 'green';
        }else if(risk > 40 && risk < 70){
          return 'orange';
        }else {
          return 'red';
        }
      }
    
    
  }
};
</script>

<style>
.red>div>.fill, .red>div>.bar{
  border-color: red !important;
}
.c100.red:hover > span {
  color: red !important;
}
.map-frame {
  height: 55vh;
  width: 100%;
/*   We use outline over border as has issues in some cases */
  /* outline: 1px solid black; */
}
#catasto{
  height: 100%;
}
</style>

